<template>
  <div class="add-teacher-note-form">
    <div class="add-teacher-note-button">
      <vs-button icon="add" type="flat" @click="addNewNote = true"
        >Add note</vs-button
      >
    </div>
    <note-edit-form
      v-if="addNewNote"
      :note="note"
      @closeForm="() => (this.addNewNote = false)"
      @noteSaved="noteSavedHandler"
    />
  </div>
</template>

<script>
import NoteEditForm from "./noteEditForm.vue";
export default {
  props: ["lesson_id"],
  data() {
    return {
      addNewNote: false,
      note: {
        lesson: {
          id: this.lesson_id,
        },
      },
    };
  },
  components: {
    NoteEditForm,
  },
  methods: {
    noteSavedHandler: async function () {
      const { lesson_ids } = this;
      await this.$store.dispatch("teachers/getNotesAction", {
        lesson_ids: [lesson_ids],
      });
    },
  },
};
</script>

<style>
</style>