<template>
  <div class="lesson-view">
    <div v-if="!prevLesson.id">No previous lesson</div>
    <div class="lesson-nav" v-else>
      <PrevLessonTitle :lesson="prevLesson" />
    </div>
    <div class="lesson" v-if="prevLesson.id">
      <vs-alert
        :active="prevLesson.cancelled"
        color="danger"
        icon="new_releases"
        size="small"
      >
        <b>Lesson canceled </b>
        <p>{{ prevLesson.cancel_description }}</p>
      </vs-alert>
      <LessonView
        :lesson="prevLesson"
        :editable="false"
        @updateLesson="updateLesson"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { mapGetters } from "vuex";
import LessonView from "@/components/lessons/lessonView.vue";
import PrevLessonTitle from "@/components/lessons/prevLessonTitle.vue";

export default {
  name: "lessonView",
  data() {
    return {
      // prevLesson: {}
    };
  },
  components: { PrevLessonTitle, LessonView },
  computed: {
    ...mapState({
      group: (state) => state.groups.singleGroup,
      lesson: (state) => state.lessons.lesson,
      prevLesson: (state) => state.lessons.prevLesson,
      lessons: (state) => state.lessons.lessons,
    }),
  },
  watch: {
    prevLesson() {},
  },
  methods: {
    updateLesson: function () {
      this.$store.dispatch("lessons/getPrevLessonAction", {
        id: this.lesson.id,
      });
    },
  },
};
</script>

<style>
</style>