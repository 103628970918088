const StudentStatuses = {
  queue: 1,
  testing: 2,
  active: 3,
  allAccepted: 4,
  inactive: 5,
  trial: 6,
  all: 7,
}

export default StudentStatuses
