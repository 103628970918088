<template>
  <div :class="`lesson-teacher teacher ${type}`">
    <vs-avatar
      :src="
        teacher.options && teacher.options.avatar
          ? `${proxy}/static/files/${teacher.options.avatar}`
          : undefined
      "
    ></vs-avatar>
    <div class="lesson-teacher__data">
      <div class="lesson-teacher__nickname">
        {{ teacher.nickname }}
      </div>
      <div class="lesson-teacher__type" v-if="type == 'main'">Main teacher</div>
      <div class="lesson-teacher__type" v-else-if="type == 'other'">
        Sub teacher
      </div>
      <div class="lesson-teacher__type" v-else-if="type == 'assistant'">
        Assistant
      </div>
    </div>
    <div class="lesson-teacher__status" v-if="dateEnd">
      <vs-icon icon="done" color="success"></vs-icon>
      <span class="lesson-teacher__date">{{ dateEnd }}</span>
    </div>

    <div class="lesson-teacher__status" v-else-if="teacher.date_start">
      <vs-icon icon="fiber_manual_record" color="primary"></vs-icon>
      <span class="lesson-teacher__date">
        <span>In progress from</span>
        <span>
          {{ $moment(teacher.date_start * 1000).format("DD MMMM YYYY HH:mm") }}
        </span>
      </span>
    </div>
    <div v-else class="not-started">
      <vs-icon icon="remove"></vs-icon>
      Not started
    </div>
    <div
      class="lesson-teacher__reopen"
      v-if="dateEnd && $can('manage', 'lesson')"
    >
      <vs-button
        icon="settings_backup_restore"
        type="flat"
        @click="reopenLessonHandle"
        size="small"
        title="Reopen the lesson for this teacher"
      ></vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["teacher", "type"],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    };
  },
  computed: {
    dateEnd: function () {
      let dateEnd;
      if (this.type == "assistant") {
        dateEnd = this.teacher.visited_at;
      } else {
        dateEnd = this.teacher.date_end;
      }
      if (dateEnd)
        return this.$moment(dateEnd * 1000).format("DD MMMM YYYY HH:mm");

      return undefined;
    },
  },
  methods: {
    reopenLessonHandle: function () {
      this.$emit("reopenLesson", this.teacher.id);
    },
  },
};
</script>

<style lang="scss">
.lesson-teachers {
  .lesson-teacher {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: 20px;
    padding: 7px;
    border-radius: 10px;
    width: 54%;
    min-width: 500px;
    &.main {
      background-color: #f1f1f1;
      box-shadow: 0 1px 3px 1px #ccc;
    }
    &__nickname {
      font-weight: 600;
      font-size: 1.1em;
    }
    &__type {
      font-size: 0.8em;
      color: var(--sub-color);
    }
    &__data {
      width: 200px;
      margin-left: 7px;
    }
    .not-started {
      color: var(--font-negative-color);
      font-size: 0.8rem;
      // margin-left: 2rem;
    }
    &__status {
      text-align: left;
      font-size: 0.8rem;
      display: flex;
      flex-flow: row nowrap;
    }

    &__date {
      padding: 2px 7px;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      &__start {
        margin-left: 1.2rem;
        font-weight: 600;
      }
    }
    &__reopen {
      display: none;
      margin-left: 1rem;
    }
    &:hover {
      .lesson-teacher__reopen {
        display: block;
      }
    }
  }
}
</style>