<template>
  <div class="panel-view collapsible">
    <tasks-wrapper place_type="lesson" :place_id="lesson.id" :lesson="lesson" />
    <div
      class="lesson-teachers collapsible"
      :class="{ opened: teachersOpened }"
      v-if="otherTeachers.length || assistants.length"
    >
      <div class="lesson-teachers__header collapsible-header">
        <div
          class="collapsible__button"
          @click="teachersOpened = !teachersOpened"
        >
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
        </div>
        <h2>Teachers ({{ otherTeachers.length + assistants.length + 1 }})</h2>
      </div>
      <div class="collapsible_content" v-if="teachersOpened">
        <lesson-teacher-view
          :teacher="lesson.main_teacher"
          type="main"
          @reopenLesson="reopenLessonHandler"
        />
        <lesson-teacher-view
          :teacher="teacher"
          type="other"
          v-for="teacher in otherTeachers"
          :key="teacher.id"
          @reopenLesson="reopenLessonHandler"
        />
        <lesson-teacher-view
          :teacher="teacher"
          type="assistant"
          v-for="teacher in assistants"
          :key="teacher.id"
          @reopenLesson="reopenLessonHandler"
        />
      </div>
    </div>
    <div
      class="lesson-attendance collapsible"
      :class="{ opened: attendanceOpened }"
    >
      <div class="lesson-attendance__header collapsible-header">
        <div
          class="collapsible__button"
          @click="attendanceOpened = !attendanceOpened"
        >
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
        </div>
        <h2>Attendance</h2>
      </div>
      <div class="collapsible_content" v-if="attendanceOpened">
        <NewVisitsTable
          :data="table"
          :lessonType="lesson.lesson_type"
          @addSurprise="addSurpriseHandler"
          @removeSurprise="removeSurprise"
          @bookOut="bookOutHandler"
          @addIncomeTest="addIncomeTestByStudentHandler"
          @incomeResultSend="incomeResultSendHandler"
        />
      </div>
    </div>
    <div
      class="lesson-comments collapsible"
      :class="{ opened: commentsOpened }"
    >
      <div class="lesson-comments__header collapsible-header">
        <div
          class="collapsible__button"
          @click="commentsOpened = !commentsOpened"
        >
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
        </div>
        <h2>Comments/Home work</h2>
      </div>
      <div class="collapsible_content" v-if="commentsOpened">
        <Comments v-if="lesson.id" :itemId="lesson.id" itemType="Lessons" />
      </div>
    </div>
    <div class="lesson-notes collapsible" :class="{ opened: notesOpened }">
      <div class="lesson-notes__header collapsible-header">
        <div class="collapsible__button" @click="notesOpened = !notesOpened">
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
        </div>
        <h2>My Notes ({{ filteredNotes.length || 0 }})</h2>
      </div>
      <div class="collapsible_content" v-if="notesOpened">
        <add-new-note :lesson_id="lesson.id" />
        <note-item
          v-for="note in filteredNotes"
          :key="note.id"
          :note="note"
          @updateNoteList="updateTeacherNotesHandler"
        />
      </div>
    </div>
    <div class="popup-edit" v-if="surprisePopupActive">
      <vs-popup
        class="form-popup surprise__popup"
        classContent="surprise__popup-content"
        title="Add surprise"
        :active.sync="surprisePopupActive"
        v-if="surprisePopupActive"
      >
        <surprise-form
          :students="lesson.students"
          :selectedStudentId="student.id"
          :lesson="lesson"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { mapGetters } from "vuex";
import Comments from '@/components/comments/comments.vue'

import NewVisitsTable from '@/components/students/visitTable.vue'
import SurpriseForm from '@/components/students/surprisers/surpriseForm.vue'
import LessonTeacherView from '@/components/lessons/lessonTeacherView.vue'
import eventBus from '@/eventBus'
import TasksWrapper from '../tasks/tasksWrapper.vue'
import NoteItem from '../teachers/notes/noteItem.vue'
import AddNewNote from '../teachers/notes/addNewNote.vue'

export default {
  props: ['lesson', 'editable', 'withTests'],
  name: 'lessonView',

  data() {
    return {
      lessonsHead: [],
      students: [],
      table: [],
      testData: {},
      testsExist: false,
      surprisePopupActive: false,
      student: null,
      teachersOpened: true,
      attendanceOpened: true,
      commentsOpened: true,
      notesOpened: false,
      addNewNote: false,
    }
  },
  components: {
    NewVisitsTable,
    Comments,
    SurpriseForm,
    LessonTeacherView,
    TasksWrapper,
    NoteItem,
    AddNewNote,
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      //   lesson: state => state.lessons.lesson,
      lessons: state => state.lessons.lessons,
      results: state => state.results.results,
      incomeTestsResults: state => state.results.incomeTestsResults,
      tests: state => state.books.tests,
      notes: state => state.teachers.notes,
      currentLesson: state => state.lessons.lesson,
      prevLesson: state => state.lessons.prevLesson,
    }),

    started: function() {
      return this.lesson.date_start && !this.lesson.date_end
    },
    isLessonEdit: function() {
      if (
        this.editable == true &&
        this.lesson.date_start &&
        !this.lesson.date_end
      ) {
        return true
      }
      return false
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem('user'))
    },
    booksToDeliver: function() {
      const { lesson } = this
      if (!lesson.id) return {}
      let books = JSON.parse(JSON.stringify(lesson.books_out))
      for (let key in lesson.books_to_out) {
        if (books[key]) {
          // TODO: Проверить необходимость
          const sts = [...books[key].students]
          console.log('sts', sts)
          // books[key].students = [...sts, ...lesson.books_to_out[key].students];
        } else {
          books[key] = lesson.books_to_out[key]
        }
      }
      return books
    },
    otherTeachers: function() {
      if (!this.lesson.other_teachers) return []
      return Object.values(this.lesson.other_teachers)
    },
    assistants: function() {
      if (!this.lesson.assistants) return []
      return Object.values(this.lesson.assistants)
    },
    filteredNotes: function() {
      return this.notes.filter(n => n.lesson.id == this.lesson.id)
    },
  },
  watch: {
    lesson: {
      deep: true,
      handler() {
        this.lessonsHead = this.getLessonHead()
        this.testData = this.getTests()
        this.students = this.getLessonTable()
      },
    },
    group: {
      deep: true,
      handler() {
        this.students = this.getLessonTable()
      },
    },
    results: {
      deep: true,
      handler() {
        // console.log("update results");
        // this.lessonsHead = this.getLessonHead();
        this.testData = this.getTests()
        this.students = this.getLessonTable()
      },
    },
    incomeTestsResults: {
      deep: true,
      handler() {
        // console.log("update results");
        // this.lessonsHead = this.getLessonHead();
        this.students = this.getLessonTable()
      },
    },
    notes: function(newV) {
      if (newV.length) {
        this.notesOpened = true
      }
    },
  },
  methods: {
    getLessonHead: function() {
      return [this.lesson]
    },
    getBooksToOut: function() {
      const { lesson } = this
      if (!lesson.id) return {}
      let books = lesson.books_out
        ? JSON.parse(JSON.stringify(lesson.books_out))
        : {}
      for (let key in lesson.books_to_out) {
        if (books[key]) {
          const sts = [...books[key].students]
          books[key].students = [...sts, ...lesson.books_to_out[key].students]
        } else {
          books[key] = lesson.books_to_out[key]
        }
      }
      return books
    },
    getLessonTable: function() {
      const { lesson } = this
      if (!lesson.id) return
      const group = this.lesson.group
      let status = lesson.cancelled ? 'cancelled' : null
      let surprisesExist = lesson.students.some(s => s.surprises.length)
      const isCreateSurpriseButtonView =
        !lesson.date_start || lesson.lesson_type == 'testing' ? false : true
      const isCreateIncomeTestView =
        lesson.lesson_type == 'testing' ? true : false

      let booksToOut = this.getBooksToOut()
      // let incomeTests = this.getIncomeTestsResults();

      let page = {
        page: {
          name: null,
          index: 0,
          current: true,
          type: this.lesson.lesson_type,
        },
        thead: [
          {
            value: null,
          },
          {
            value: new Date(lesson.lesson_time * 1000).getDate(),
            link_to: {
              name: 'Lessons',
              params: { lesson_id: lesson.id },
            },
            status,
          },
        ],
        rows: [],
      }
      if (this.testsExist) {
        Object.values(this.testData).forEach(t => {
          page.thead.splice(page.thead.length - 1, 0, t.head)
        })
      }
      if (surprisesExist) {
        page.thead.push({
          value: 'Surprises',
        })
      }
      if (Object.keys(booksToOut).length) {
        for (let key in booksToOut) {
          page.thead.push({
            value: booksToOut[key].name,
            icon: 'call_missed_outgoing',
          })
        }
      }
      if (this.incomeTestsResults.length) {
        page.thead.splice(1, 0, {
          value: 'Cls',
        })
        page.thead.push(
          {
            value: 'Test name',
          },
          {
            value: 'Oral',
          },
          {
            value: 'Written',
          }
        )
        if (this.user.options.roles.includes('admin')) {
          page.thead.push({
            value: 'Sent',
          })
        }
      }
      // add comment to the end of table
      page.thead.push({
        value: 'Comment',
      })

      // -- set table body
      let students = []
      if (lesson.students?.length) students = lesson.students
      else {
        // если группа без абонемента, то показываем ее учеников
        if (group && !group.group_type.with_fixed_rate) {
          if (group.students && group.students.length) {
            students = group.students
          }
        }
      }

      students.forEach(student => {
        if (
          typeof student.is_active !== 'undefined' &&
          student.is_active == false
        )
          return

        switch (student.exist) {
          case 'н':
            status = 'danger'
            break
          case 'б':
            status = 'wrong'
            break
          case '0':
            status = 'empty'
            break
          case 'ч':
            status = 'warn'
            break
          default:
            status = null
            break
        }
        const columns = [
          {
            value: student.name || '',
            rowId: student.id,
            itemId: lesson.id,
            edited: false,
            comment: null,
            columnType: 'lesson',
            columnId: student.link_id || null,
            link_to: {
              name: 'Student',
              params: { student_id: student.id },
            },
            isCreateSurpriseButtonView: isCreateSurpriseButtonView,
            isCreateIncomeTestView: isCreateIncomeTestView,
            is_trial: student.is_trial,
            isBirthdate: lesson.birthdays.some(b => b.id == student.id),
          },
          {
            value: student.exist || '',
            rowId: student.id,
            itemId: lesson.id,
            edited: this.isLessonEdit,
            columnType: 'exist',
            columnId: student.link_id || null,
            teachersOptions:
              student.teachers_options && student.teachers_options.exist
                ? student.teachers_options.exist
                : null,
            status,
          },
        ]

        if (this.testsExist) {
          Object.values(this.testData).forEach(t => {
            columns.splice(columns.length - 1, 0, t.rows[student.id])
          })
        }
        if (surprisesExist) {
          let surpriseColumn = {
            rowId: student.id,
            itemId: lesson.id,
            edited: this.isLessonEdit,
            columnType: 'surpriseView',
            columnId: student.link_id || null,
          }
          surpriseColumn.value = student.surprises.length
            ? student.surprises
            : []
          columns.push(surpriseColumn)
        }
        if (Object.keys(booksToOut).length) {
          for (let key in booksToOut) {
            const outByStudent = booksToOut[key].students.find(
              s => s.student_id == student.id
            )
            if (outByStudent) {
              columns.push({
                rowId: student.id,
                itemId: lesson.id,
                edited: this.isLessonEdit,
                columnType: 'bookOut',
                columnId: outByStudent.id || null,
                status: outByStudent.delivered_date ? 'disabled' : null,
                value: outByStudent.delivered_date || 'toGiveOut',
              })
            } else {
              columns.push({ status: 'disabled', value: '' })
            }
          }
        }
        if (this.incomeTestsResults.length) {
          const incomeResult = this.incomeTestsResults.filter(
            r => r.student.id == student.id
          )
          const resultStatus = !incomeResult.length ? 'disabled' : null
          const isEdited = !incomeResult.length ? false : true
          // вставляем класс, так как он нужен только для тестов
          columns.splice(1, 0, {
            rowId: student.id,
            itemId: lesson.id,
            edited: false,
            columnType: 'student-class',
            columnId: lesson.id,
            value: student.student_class,
          })
          columns.push(
            {
              rowId: student.id,
              itemId: lesson.id,
              edited: isEdited,
              columnType: 'income-result-name',
              columnId: lesson.id,
              status: resultStatus,
              value: incomeResult.map(r => {
                return { id: r.id, value: r.test.name }
              }),
            },
            {
              rowId: student.id,
              itemId: lesson.id,
              edited: isEdited,
              columnType: 'income-result-oral-value',
              columnId: lesson.id,
              status: resultStatus,
              value: incomeResult.map(r => {
                return { id: r.id, value: r.oral_value }
              }),
            },
            {
              rowId: student.id,
              itemId: lesson.id,
              edited: isEdited,
              columnType: 'income-result-written-value',
              columnId: lesson.id,
              status: resultStatus,
              value: incomeResult.map(r => {
                return { id: r.id, value: r.written_value }
              }),
            }
          )
          if (this.user.options.roles.includes('admin')) {
            columns.push({
              rowId: student.id,
              itemId: lesson.id,
              edited: isEdited,
              columnType: 'result-sended',
              columnId: lesson.id,
              status: resultStatus,
              value: incomeResult.map(r => {
                return {
                  id: r.id,
                  value: r.sended,
                }
              }),
            })
          }
        }
        // add column field at the end of table
        columns.push({
          value: student.comment || '',
          rowId: student.id,
          itemId: lesson.id,
          edited: this.isLessonEdit,
          columnType: 'comment',
          columnId: student.link_id || null,
          teachersOptions:
            student.teachers_options && student.teachers_options.comment
              ? student.teachers_options.comment
              : null,
        })
        page.rows.push({
          columns,
        })
      })
      this.table = [page]

      students = students.map(student => {
        return {
          lessons: [
            {
              cancelled: lesson.cancelled,
              exist: student.exist || '',
            },
          ],
          name: student.name || '',
          id: student.id,
          link_id: student.link_id || null,
          comment: student.comment || '',
          lesson_id: lesson.id,
          // TODO: books, tests
        }
      })
      students = students.sort(function(a, b) {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
      return students
    },
    getTests: function() {
      const testData = {}
      // const now = new Date().getTime() / 1000;
      if (this.withTests && this.lesson.options && this.lesson.options.tests) {
        const lessonStudentsIds = this.lesson.students.map(s => s.id)

        this.testsExist = true
        this.lesson.options.tests.forEach(test_id => {
          const test = this.tests.find(t => t.id == parseInt(test_id, 10))
          if (!test) return

          testData[test.id] = {
            head: {
              value: `${test.name} (${test.book.name})`,
              subValue: `max: ${test.max_value}`,
            },
            rows: {},
          }
          const results = this.results.filter(
            r =>
              r.test.id == test.id && r.lesson && r.lesson.id == this.lesson.id
          )
          const resultsStudentsIds = this.results.map(r => r.student.id)
          let missingStudentResultsIds = lessonStudentsIds.filter(
            d => !resultsStudentsIds.includes(d)
          )

          results.forEach(r => {
            // TODO: set edited 10 days from test_time
            // const edited = now - r.created < 60 * 60 * 24 * 10 ? true : false;
            let status = null
            if (r.rejected) status = 'disabled'
            testData[test.id].rows[r.student.id] = {
              status,
              value: r.value || '',
              rowId: r.student.id,
              itemId: test.id,
              edited: true,
              // edited: this.editable,
              // edited: this.editable && edited,
              columnType: 'test',
              columnId: r.id || null,
            }
          })

          missingStudentResultsIds.forEach(r => {
            testData[test.id].rows[r] = {
              status: 'disabled',
              value: 'Missing or deleted',
              rowId: r,
              itemId: test.id,
              edited: false,
              columnType: 'test',
              columnId: null,
            }
          })
        })
      }
      return testData
    },
    addSurpriseHandler: function(studentId) {
      console.log(this.group)
      this.student = this.lesson.students.find(s => s.id == studentId)
      this.surprisePopupActive = true
    },
    removeSurprise: function(surprise) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete surprise',
        text: 'Are you sure you want to delete the surprise?',
        accept: () => {
          this.$store
            .dispatch('surprises/deleteSurpriseAction', { surprise })
            .then(() => {
              this.updateLesson()
            })
        },
      })
    },
    closePopup: function() {
      this.surprisePopupActive = false
      this.student = null
      this.updateLesson()
    },
    updateLesson: function() {
      this.$emit('updateLesson')
    },
    bookOutHandler: function(out_id) {
      const { lesson } = this
      this.$store
        .dispatch('books/giveOutBookToStudentAction', {
          out_id,
          lesson_id: lesson.id,
        })
        .then(() => {
          this.updateLesson()
        })
    },
    addIncomeTestByStudentHandler: async function(student_id) {
      let data = [
        {
          lesson_id: this.lesson.id,
          student_id: student_id,
        },
      ]

      await this.$store
        .dispatch('results/addIncomeTestResultAction', { data })
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `Tests added`,
            color: 'success',
            position: 'top-right',
          })
        })
      await this.$store.dispatch(
        'results/getIncomeTestsResultsByLessonAction',
        {
          lesson_id: this.lesson.id,
        }
      )
    },
    incomeResultSendHandler: async function(result_id) {
      const sended = new Date().getTime() / 1000
      await this.$store
        .dispatch('results/sendIncomeResultAction', { result_id, sended })
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `Tests mark as sended`,
            color: 'success',
            position: 'top-right',
          })
        })
      await this.$store.dispatch(
        'results/getIncomeTestsResultsByLessonAction',
        {
          lesson_id: this.lesson.id,
        }
      )
    },
    updateTeacherNotesHandler: async function() {
      const lesson_ids = [this.currentLesson.id, this.prevLesson.id]
      await this.$store.dispatch('teachers/getNotesAction', { lesson_ids })
    },
    reopenLessonHandler: function(teacherId) {
      const { id } = this.lesson
      this.$store
        .dispatch('lessons/reopenLessonAction', { id, teacherId })
        .then(() => {
          eventBus.$emit('updateLessonStatus')
          this.$vs.notify({
            title: 'Reopened!',
            text: '',
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
  },

  created() {
    this.lessonsHead = this.getLessonHead()
    this.students = this.getLessonTable()
    setTimeout(() => {
      eventBus.$on('toggleComments', () => {
        this.commentsOpened = false
        setTimeout(() => {
          this.commentsOpened = true
        }, 100)
      })
    }, 100)
  },
  beforeDestroy() {
    eventBus.$off('toggleComments')
    this.$store.dispatch('results/clearIncomeResultsAction')
  },
}
</script>
