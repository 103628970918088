<template>
  <div class="teacher-note">
    <div class="teacher-note__info" v-if="!editNote">
      <div class="teacher-note__header">
        <teacher :teacher="note.teacher">{{ note.teacher.nickname }}</teacher>
        <div class="teacher-note__created">
          {{ $moment(note.created * 1000).format("DD MMM YYYY HH:mm") }}
        </div>
        <div class="teacher-note__updated">
          <div
            class="teacher-note__updated__text"
            v-if="note.updated"
            :title="
              note.updated
                ? $moment(note.updated * 1000).format('DD MMM YYYY HH:mm')
                : ''
            "
          >
            Edited
          </div>
        </div>

        <vs-button
          type="flat"
          icon="edit"
          size="small"
          title="Edit note"
          color="dark"
          @click="editNote = true"
          >Edit</vs-button
        >
        <vs-button
          type="flat"
          icon="delete"
          size="small"
          color="danger"
          title="Delete note"
          @click="deleteNote"
          >Delete</vs-button
        >
      </div>
      <div
        class="teacher-note__body comment__text"
        @mouseup="selectHandler($event)"
        title="Edit note"
      >
        <div
          style="white-space: pre-line"
          v-html="note.note"
          class="comment__text__block"
        ></div>
        <vs-icon
          icon="subject"
          class="teacher-note__body__icon"
          size="small"
        ></vs-icon>
      </div>
    </div>
    <div class="teaccher-note__edit" v-else>
      <note-edit-form
        :note="note"
        @closeForm="() => (editNote = false)"
        @noteSaved="$emit('updateNoteList')"
      />
    </div>
  </div>
</template>

<script>
import Teacher from "../../common/elements/teacher.vue";
import NoteEditForm from "./noteEditForm.vue";
export default {
  props: ["note"],
  data() {
    return {
      editNote: false,
    };
  },
  components: {
    NoteEditForm,
    Teacher,
  },
  methods: {
    deleteNote: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to delete note?",
        accept: () => {
          const { id } = this.note;
          this.$store.dispatch("teachers/deleteNoteAction", { id }).then(() => {
            this.$emit("updateNoteList");
          });
        },
      });
    },
    selectHandler: function () {
      if (!document.getSelection().toString().length) {
        this.editNote = true;
      }
    },
  },
};
</script>

<style
    NoteEditForm lang="scss">
.teacher-note {
  margin: 0.5rem 0;
  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & > *:not(.vs-button) {
      margin-right: 0.5rem;
    }
  }
  &__created,
  &__updated,
  .lesson-element {
    font-size: 0.75rem;
    color: var(--sub-color);
  }
  &__updated {
    flex: 1;
    color: red;
  }
  &__body {
    margin: 0;
    padding: 15px 25px 15px 2.5rem;
    border-radius: 10px;
    position: relative;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    cursor: text;
    .comment__text__block {
      font-size: 0.9em;
    }
    &__icon {
      position: absolute;
      top: 10px;
      left: 0.5rem;
    }
    &:hover {
      box-shadow: 0 0 10px 4px #f1f1f1;
    }
  }
}
</style>