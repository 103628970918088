<template>
  <div
    class="task-item"
    :class="{
      student: task.students.length,
      group: task.group.id,
      lesson: task.lesson.id,
    }"
  >
    <div
      class="task-item__importance__border"
      :class="importance.name.toLowerCase()"
    ></div>
    <vs-row vs-w="12" class="task-item-info">
      <div class="task-item-info__id" :class="status.class">#{{ task.id }}</div>
      <div class="task-item-info__name">
        {{ task.name }}
      </div>
      <task-status :status="status" />
    </vs-row>
    <vs-row vs-w="12" class="task-item-sub">
      <div class="task-item-sub__places">
        <div class="task-item-place__group" v-if="task.group.id">
          <router-link
            :to="{ name: 'Group', params: { group_id: task.group.id } }"
            class="group-link"
            v-if="place_type == 'teacher'"
          >
            <vs-chip color="rgba(0, 0, 200, 0.5)">
              <vs-avatar icon="people" :color="task.group.color"></vs-avatar>
              <span>{{ task.group.name }}</span>
            </vs-chip>
          </router-link>
          <router-link
            :to="{ name: 'Group', params: { group_id: task.group.id } }"
            class="group-link"
            v-else
          >
            <vs-avatar
              icon="people"
              :color="task.group.color"
              size="16px"
            ></vs-avatar>
            <span>{{ task.group.name }}</span>
          </router-link>
        </div>
        <div class="task-item-place__lesson" v-if="task.lesson.id">
          <vs-avatar icon="query_builder" size="16px" color="#aaa"></vs-avatar>
          <router-link
            :to="{ name: 'Lessons', params: { lesson_id: task.lesson.id } }"
            class="lesson-link"
          >
            <span>{{ task.lesson.name }} - </span>
            <span>{{
              $moment(task.lesson.lesson_time * 1000).format('DD.MM.YYYY HH:mm')
            }}</span>
          </router-link>
        </div>
      </div>
    </vs-row>
    <vs-row class="task-item-sub task-students">
      <div
        class="task-item-place__student"
        v-for="student in task.students"
        :key="student.id"
      >
        <vs-avatar icon="person" size="16px"></vs-avatar>
        <router-link
          :to="{ name: 'Student', params: { student_id: student.id } }"
          class="student-link"
        >
          <span
            >{{ student.last_name }}
            <span v-if="task.students.length <= 3">{{
              student.first_name
            }}</span
            ><span v-else>{{ student.first_name.slice(0, 1) }}.</span></span
          >
        </router-link>
      </div>
    </vs-row>
    <vs-row class="task-item-sub task-teachers">
      <div class="task-item-sub__importance">
        <vs-icon
          :icon="importance.icon"
          :color="importance.color"
          :title="importance.name"
        ></vs-icon>
        {{ importance.name }}
      </div>
      <div class="task-item-sub__teachers">
        <div
          class="task-item-sub__teacher"
          v-for="t in task.teachers"
          :key="t.id"
        >
          <teacher-chip-view :teacher="t" size="middle" />
        </div>
      </div>
      <task-dates :task="task"></task-dates>
    </vs-row>
  </div>
</template>

<script>
import { TASK_IMPORTANCE, TASK_STATUS } from './dicts'
import taskStatus from './taskStatus.vue'
import teacherChipView from '@/components/teachers/teacherChipView'
import taskDates from './taskDates.vue'

export default {
  components: { taskStatus, teacherChipView, taskDates },
  props: ['task', 'place_type'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    importance: function() {
      return (
        TASK_IMPORTANCE.find(i => i.id == this.task.importance) ||
        TASK_IMPORTANCE[1]
      )
    },
    status: function() {
      return TASK_STATUS.find(s => s.id == this.task.status) || TASK_STATUS[0]
    },
  },
}
</script>

<style lang="scss"></style>
