<template>
  <div class="prev-lesson-title">
    <h2 class="status-bar__title" :class="{ crossed: lesson.cancelled }">
      {{ lesson.name }}
      <lesson-online v-if="lesson.is_online" />
    </h2>

    <div class="status-bar__teacher">
      <vs-chip transparent>
        <vs-avatar
          :src="
            lesson.teacher.options && lesson.teacher.options.avatar
              ? `${proxy}/static/files/${lesson.teacher.options.avatar}`
              : undefined
          "
        ></vs-avatar>
        {{ lesson.teacher.nickname }}
      </vs-chip>
    </div>
    <div class="status-bar__lesson-time" :class="{ crossed: lesson.cancelled }">
      <vs-icon icon="query_builder"></vs-icon>
      {{ $moment(lesson.lesson_time * 1000).format('DD MMMM YYYY HH:mm') }}
    </div>
    <div
      v-if="lesson.date_end"
      class="status-bar__lesson-time"
      :class="{ crossed: lesson.cancelled }"
    >
      <vs-icon icon="done" color="success"></vs-icon>
      Done: {{ $moment(lesson.date_end * 1000).format('DD.MM.YYYY HH:mm') }}
    </div>
    <div class="status-bar__room">
      <vs-icon :icon="lesson.room.icon"></vs-icon>
      {{ lesson.room.name }}
    </div>
  </div>
</template>

<script>
import LessonOnline from '@/components/common/elements/lessonOnline.vue'
export default {
  name: 'lessonView',
  props: ['lesson'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  components: {
    LessonOnline,
  },
  methods: {
    moveLessonBack() {
      this.$store.dispatch('lessons/getPrevLessonAction', {
        id: this.lesson.prev_id,
      })
    },
  },
}
</script>

<style lang="scss">
.prev-lesson-title {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  & > * {
    width: 45%;
    margin: 5px;
  }
  .status-bar {
    &__title {
      display: flex;
    }
  }
}
</style>
