<template>
  <div class="lesson-visit-table" :class="size" v-if="data[pageIndex]">
    <div class="visits__pages" v-if="data.length > 1">
      <!-- <LessonStat :statistic="prevStats" /> -->
      <div class="prev">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="downPage"
        ></vs-button>
      </div>
      <div class="page-name">{{ data[pageIndex].page.name }}</div>
      <div class="next">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="upPage"
        ></vs-button>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th
            :class="item.status"
            v-for="(item, index) in data[pageIndex].thead"
            :key="index"
          >
            <router-link :to="item.link_to" v-if="item.link_to">{{
              item.value
            }}</router-link>
            <span v-else>{{ item.value }}</span>
            <sub v-if="item.subValue" class="sub">{{ item.subValue }}</sub>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in data[pageIndex].rows" :key="row.rowId">
          <td
            v-for="(item, index) in row.columns"
            :key="index"
            :class="
              `${item.status ? item.status : ''} ${
                item.edited ? 'edit' : ''
              } col-${item.columnType} ${
                item.surprise ? 'with-add-surprise' : ''
              }
                ${item.is_trial ? 'is-trial' : ''}`
            "
            class="table-cell"
          >
            <div class="table-cell__wrapper">
              <router-link :to="item.link_to" v-if="item.link_to">
                {{ item.value }}
                <span
                  title="birthday"
                  class="student-birthdate markdown grey small"
                  v-if="item.isBirthdate"
                >
                  🎉
                </span>
              </router-link>

              <div
                v-else-if="item.columnType == 'surpriseView'"
                class="lesson-surprises"
              >
                <vs-chip
                  v-for="surp in item.value"
                  :key="surp.id"
                  :closable="$can('remove-surprise')"
                  title="Delete surprise"
                  @click="$emit('removeSurprise', surp)"
                >
                  <vs-avatar
                    :icon="surp.category.icon"
                    v-if="surp.category"
                    :color="surp.category.color"
                    :badge="surp.stickers"
                  />
                  {{ surp.name }}</vs-chip
                >
              </div>
              <div v-else-if="item.columnType == 'bookOut'" class="book-out">
                <span v-if="item.value == 'toGiveOut'">
                  <vs-button
                    icon="call_missed_outgoing"
                    color="dark"
                    type="flat"
                    size="small"
                    @click="$emit('bookOut', item.columnId, item.itemId)"
                    >Give out</vs-button
                  >
                </span>
                <span v-else-if="item.value">
                  <vs-icon icon="done" color="success"></vs-icon>
                </span>
              </div>
              <div
                v-else-if="
                  item.columnType && item.columnType.startsWith('income-')
                "
              >
                <div
                  v-for="(icomeResult, rIndex) in item.value"
                  :key="rIndex"
                  :class="item.columnType"
                  class="income-tests"
                >
                  <textarea
                    :value="icomeResult.value"
                    :data-index="`${item.columnType}-${rowIndex}`"
                    @keydown="nameKeydown($event, item.columnType, rowIndex)"
                    @focus="
                      cellFocus(
                        $event,
                        item.columnType,
                        item.columnId,
                        icomeResult.id,
                        item.rowId
                      )
                    "
                    @blur="lostFocus($event)"
                    @keyup="
                      changeCell(
                        $event,
                        item.columnType,
                        item.columnId,
                        icomeResult.id,
                        item.rowId
                      )
                    "
                    class="income-test-input"
                    type="text"
                  ></textarea>
                </div>
              </div>
              <div
                v-else-if="item.columnType == 'result-sended'"
                class="result-sended"
              >
                <div
                  v-for="(icomeSended, rIndex) in item.value"
                  :key="rIndex"
                  :class="item.columnType"
                >
                  <span v-if="!icomeSended.value">
                    <vs-button
                      icon="send"
                      icon-after
                      color="primary"
                      type="flat"
                      size="small"
                      @click="$emit('incomeResultSend', icomeSended.id)"
                      >Send</vs-button
                    >
                  </span>
                  <span v-else>
                    <vs-icon
                      icon="done"
                      color="success"
                      :title="
                        `Sended at ${$moment(icomeSended.value * 1000).format(
                          'YYYY-MM-DD HH:mm'
                        )}`
                      "
                    ></vs-icon>
                  </span>
                </div>
              </div>
              <span v-else-if="item.edited" class="edit-field">
                <textarea
                  :value="item.value"
                  :data-index="`${item.columnType}-${rowIndex}`"
                  @keydown="nameKeydown($event, item.columnType, rowIndex)"
                  @focus="
                    cellFocus(
                      $event,
                      item.columnType,
                      item.columnId,
                      item.itemId,
                      item.rowId
                    )
                  "
                  @blur="lostFocus($event)"
                  @keyup="
                    changeCell(
                      $event,
                      item.columnType,
                      item.columnId,
                      item.itemId,
                      item.rowId
                    )
                  "
                ></textarea>
              </span>
              <span v-else v-html="`${item.value}`"></span>
              <div
                class="comment"
                v-if="item.comment && !isLessonTable"
                :title="item.comment"
              ></div>

              <span v-if="item.teachersOptions" class="teacher-options">
                <vs-icon
                  icon="info"
                  size="small"
                  :title="
                    `${item.teachersOptions.teacher.nickname}\n${$moment(
                      item.teachersOptions.datetime * 1000
                    ).format('DD.MM.YYYY HH.mm')}`
                  "
                ></vs-icon>
              </span>
              <span
                class="add-surprise"
                v-if="item.isCreateSurpriseButtonView"
                title="Add surprise"
                @click="$emit('addSurprise', item.rowId, item.itemId)"
              >
                <vs-icon icon="star" color="primary"></vs-icon>
              </span>
              <span
                class="add-income-test"
                v-if="item.isCreateIncomeTestView"
                title="Add test to student"
                @click="$emit('addIncomeTest', item.rowId)"
                ><vs-icon icon="add" color="primary"></vs-icon>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="save-tooltip">
      <div class="save-tooltip__content">
        <vs-button
          data-type="cell-action-button"
          type="flat"
          icon="check"
          title="Save"
          @click="saveHandle"
        ></vs-button>
        <vs-button
          data-type="cell-action-button"
          type="flat"
          color="dark"
          icon="close"
          title="Cancel"
          @click="cancelEditHandle"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTable',
  props: [
    'data',
    'theadData',
    'tbodyData',
    'isLessonTable',
    'size',
    'lessonType',
  ],
  data() {
    return {
      withMonthPages: true,
      pageIndex: 0,
      editedCell: {},
      selectedCellElement: null,
      sourceValue: '',
    }
  },
  methods: {
    downPage: function() {
      const downIndex = this.pageIndex - 1
      if (downIndex >= 0) this.pageIndex = downIndex
    },
    upPage: function() {
      const upIndex = this.pageIndex + 1
      if (upIndex < this.data.length) this.pageIndex = upIndex
    },
    changeCell: function(e, columnType, columnId, itemId, rowId) {
      this.editedCell = {
        link_id: columnId,
        id: itemId,
        student_id: rowId,
        columnType,
      }
      this.editedCell[columnType] = e.target.value
    },
    cellFocus: function(e, columnType, columnId, itemId, rowId) {
      const tooltip = document.querySelector('.save-tooltip')
      tooltip.style.display = 'block'
      e.target.parentElement.appendChild(tooltip)
      this.selectedCellElement = e.target
      // предзаполнение объекта
      this.sourceValue = e.target.value

      this.editedCell = {
        link_id: columnId,
        id: itemId,
        student_id: rowId,
        columnType,
      }
      this.editedCell[columnType] = e.target.value
    },
    lostFocus: function() {
      // console.log(e);
    },
    nameKeydown(e, columnType, index) {
      if (columnType == 'exist') {
        if (e.key == 'Backspace') return
        if (!/^[-|1|н|б|ч|0]$/.test(e.key)) {
          e.preventDefault()
        }
      }
      if (e.key == 'Enter') {
        if (
          columnType == 'exist' ||
          columnType == 'test' ||
          columnType.startsWith('income-')
        ) {
          this.saveHandle().then(() => {
            e.target.classList.add('saved')

            const nextField = document.querySelector(
              `textarea[data-index="${columnType}-${index + 1}"]`
            )
            if (nextField) {
              nextField.focus()
            }
          })
          return
        }
      }

      this.selectedCellElement.classList.add('changed')
    },
    saveHandle: function() {
      if (this.editedCell.columnType == 'test') {
        return this.saveTestHandle()
      } else if (this.editedCell.columnType.startsWith('income-')) {
        return this.saveIncomeTestHandle()
      } else {
        return this.saveLessonHandle()
      }
    },
    saveLessonHandle: function() {
      return new Promise((resolve, reject) => {
        if (this.editedCell?.exist == '-') {
          this.editedCell.exist = 'н'
        }
        this.$store
          .dispatch('lessons/visitLessonAction', {
            data: this.editedCell,
          })
          .then(() => {
            this.selectedCellElement.classList.remove('changed')

            this.editedCell = {}
            this.tooltipHideAction()
            this.$vs.notify({
              title: 'Lesson saved',
              text: `Changes in lesson saved`,
              color: 'success',
              position: 'top-right',
            })
            resolve()
          })
          .catch(message => {
            this.$vs.notify({
              title: 'Lesson error',
              text: `${message}`,
              color: 'danger',
              position: 'top-right',
              time: 6000,
            })
            reject()
          })
      })
    },
    saveTestHandle: function() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('results/setTestResultAction', { data: this.editedCell })
          .then(() => {
            this.selectedCellElement.classList.remove('changed')

            this.editedCell = {}
            this.tooltipHideAction()
            this.$vs.notify({
              title: 'Test result saved',
              text: `Changes in tests results saved`,
              color: 'success',
              position: 'top-right',
            })
            resolve()
          })
          .catch(message => {
            this.$vs.notify({
              title: 'Test result save error',
              text: `${message}`,
              color: 'danger',
              position: 'top-right',
              time: 6000,
            })
            reject()
          })
      })
    },
    saveIncomeTestHandle: function() {
      const { id, student_id, link_id, columnType } = this.editedCell
      const data = {
        id,
        student_id,
        lesson_id: link_id,
      }
      if (columnType.endsWith('-name')) {
        data.name = this.editedCell[columnType]
      } else if (columnType.endsWith('-oral-value')) {
        data.oral_value = this.editedCell[columnType]
      } else if (columnType.endsWith('-written-value')) {
        data.written_value = this.editedCell[columnType]
      }
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('results/setIncomeTestResultAction', {
            data: [data],
          })
          .then(() => {
            this.selectedCellElement.classList.remove('changed')

            this.editedCell = {}
            this.tooltipHideAction()
            this.$vs.notify({
              title: 'Testing saved',
              text: ``,
              color: 'success',
              position: 'top-right',
            })
            resolve()
          })
          .catch(message => {
            this.$vs.notify({
              title: 'Testing save error',
              text: `${message}`,
              color: 'danger',
              position: 'top-right',
              time: 6000,
            })
            reject()
          })
      })
    },
    cancelEditHandle: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm`,
        text: 'Are You sure to cancel changes?',

        accept: this.acceptCancelEdit,
      })
    },
    acceptCancelEdit: function() {
      this.editedCell = {}
      this.selectedCellElement.value = this.sourceValue
      this.selectedCellElement.classList.remove('changed')
      this.selectedCellElement = null
      this.sourceValue = ''

      this.tooltipHideAction()
    },
    tooltipHideAction: function() {
      const tooltip = document.querySelector('.save-tooltip')
      tooltip.style.display = 'none'
    },
  },
  created() {
    const index = this.data.findIndex(d => d.page.current)
    this.pageIndex = index != -1 ? index : 0
  },
}
</script>

<style lang="scss">
.lesson-visit-table {
  overflow-x: auto;
  table {
    border-collapse: collapse;
    margin-bottom: 30px;
    tbody {
      tr {
        height: 2.7rem;
      }
    }

    td,
    th {
      border: 1px solid #777;
      text-align: center;
      height: 1px;
      .table-cell__wrapper {
        position: relative;
        padding: 7px 15px;
        height: 100%;
        width: 100%;
      }
      &.visits-cell {
        text-align: center;
      }
      &.item-name {
        width: 250px;
      }
      &.editable {
        cursor: pointer;
        &:hover {
          background-color: #eee;
        }
      }
      &:nth-child(1) {
        text-align: left;
      }
      &.edit {
        .table-cell__wrapper {
          padding: 0 !important;
        }
      }
      &.col-income-result-name {
        min-width: 150px;
      }
      &.col-comment {
        min-width: 150px;
        max-width: 400px;
        word-break: break-word;
      }
      &.is-trial {
        box-shadow: inset 0px 0px 0px 2px #5b3cc4;
        position: relative;
        &::after {
          content: 'trial';
          font-size: 0.8rem;
          position: absolute;
          top: 2px;
          right: 4px;
          color: #5b3cc4;
        }
      }
      sub {
        display: block;
        font-weight: normal;
      }
      .col-surpriseView {
        padding: 7px;
      }
    }
    .cancelled {
      background-color: #ffc8f0;
    }
    .wrong {
      background-color: #fff3c8;
    }
    .empty {
      background-color: #f8e291;
    }
    .danger {
      background-color: #ffc8c8;
    }
    .warn {
      background-color: #f2c8ff;
    }
    .disabled {
      background-color: #e6e6e6;
    }
    .comment {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #c00;
      cursor: pointer;
      margin: 0 auto;
    }
  }
  .visits__pages {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    & > * {
      margin: 5px;
    }
    .page-name {
      min-width: 100px;
      text-align: center;
    }
  }
  &.small {
    table {
      td,
      th {
        padding: 2px 7px;
        position: relative;
      }
    }
  }
  .edit-field {
    width: 100%;
    height: 100%;
    margin: 0;
    display: inline-block;
    position: relative;
    input,
    textarea {
      border: none;
      background: none;
      width: auto;
      padding: 10px;
      width: 100%;
      height: 100%;
      &.changed {
        background-color: #efefef;
        outline: 2px solid #c00;
      }
    }
  }
  .col-exist {
    .table-cell__wrapper {
      text-align: center;
    }
    &.edit {
      max-width: 50px;
    }
    textarea {
      resize: none;
    }
  }
  .col-test {
    .table-cell__wrapper {
      min-width: 70px;
    }
    textarea {
      resize: none;
      text-align: center;
    }
  }
  .col-comment {
    .table-cell__wrapper {
      min-width: 350px;
      white-space: pre-line;
    }
  }
}

.add-surprise,
.add-income-test {
  visibility: hidden;
  height: 2rem;
  background-color: #eee;
  border-radius: 7px 0 0 7px;
  position: absolute !important;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  box-shadow: -3px 1px 15px 1px #eee;
  padding: 0 5px;
  cursor: pointer;
}
.col-lesson {
  position: relative;
  &:hover {
    .add-surprise {
      visibility: visible;
    }
    .add-income-test {
      visibility: visible;
    }
  }
}
.lesson-surprises {
  max-width: 250px;
  .con-vs-chip {
    margin-bottom: 7px;
  }
}
.teacher-options {
  position: absolute;
  bottom: 0;
  left: 1px;
  cursor: pointer;
  .vs-icon {
    font-size: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.25;
    &:hover {
      opacity: 1;
    }
  }
}
.col-income-result-oral-value,
.col-income-result-written-value {
  max-width: 50px;
  height: 100%;
  text-align: center;
}
.table-cell__wrapper {
  & > div {
    height: 100%;
  }
  textarea {
    transition: all 0.1s;
    &:focus {
      box-shadow: 0 0 5px 1px #bbb;
      // background-color: #f1f1f1;
      z-index: 20;
    }
  }
  .income-tests {
    position: relative;
    textarea {
      width: 100%;
      height: 100%;
      border: none;
      resize: none;
      padding: 0.2rem;
      text-align: center;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #777;
    }
  }
  .student-birthdate {
    // display: block;
    margin-left: 0;
    width: auto;
    vertical-align: middle;
    margin-right: 3px;
  }
}
</style>
