var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lesson-form"},[(_vm.lesson.cancelled)?_c('div',{staticClass:"lesson-form__status",class:{ cancelled: _vm.lesson.cancelled }},[_vm._v(" Lesson Cancelled ")]):_vm._e(),(_vm.lesson.date_start && !_vm.lesson.date_end)?_c('div',{staticClass:"lesson-form__status",class:{ started: _vm.lesson.date_start && !_vm.lesson.date_end }},[_vm._v(" Lesson in work ")]):_vm._e(),(_vm.lesson.date_end)?_c('div',{staticClass:"lesson-form__status",class:{ done: _vm.lesson.date_end }},[_vm._v(" Lesson done ")]):_vm._e(),_c('div',{staticClass:"groups__form-content form-content-two-parts"},[_c('div',{staticClass:"inline-part left"},[_c('div',{staticClass:"field"},[_c('vs-input',{attrs:{"label":"Lesson name","size":"large"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(_vm.lesson.lesson_type == 'testing' || _vm.groupWithFixedRate)?_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Students")]),_c('v-select',{class:{ error: !_vm.$v.students.required },attrs:{"label":"name","options":_vm.studentsList,"reduce":function (item) { return item.id; },"clearable":"","multiple":"","placeholder":"Select students","selectable":_vm.studentSelectableHandler},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person"}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person"}}),_vm._v(" "+_vm._s(name)+" ")]}}],null,false,1022623501),model:{value:(_vm.students),callback:function ($$v) {_vm.students=$$v},expression:"students"}}),_c('vs-button',{attrs:{"size":"small","type":"flat"},on:{"click":_vm.selectAllStudentsHandler}},[_vm._v("Select all")]),_c('vs-button',{attrs:{"size":"small","type":"flat","color":"danger"},on:{"click":function($event){_vm.students = []}}},[_vm._v("Remove all")])],1):_vm._e(),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Teacher")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select teacher"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nickname = ref.nickname;
var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined,"size":"small"}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
                  var nickname = ref.nickname;
                  var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined,"size":"small"}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.teacher_id),callback:function ($$v) {_vm.teacher_id=$$v},expression:"teacher_id"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Room")]),_c('v-select',{class:{ error: !_vm.$v.room_id.required },attrs:{"label":"name","options":_vm.rooms,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select room"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var name = ref.name;
                  var icon = ref.icon;
return [_c('vs-icon',{attrs:{"icon":icon}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
                  var name = ref.name;
                  var icon = ref.icon;
return [_c('vs-icon',{attrs:{"icon":icon}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.room_id),callback:function ($$v) {_vm.room_id=$$v},expression:"room_id"}}),(!_vm.$v.room_id.required)?_c('div',{staticClass:"error"},[_vm._v("Field is required")]):_vm._e()],1),(_vm.lesson.lesson_type == 'tutor')?_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("School Year")]),_c('v-select',{attrs:{"label":"name","options":_vm.years,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select schoolyear"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                  var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,3489911677),model:{value:(_vm.selectedSchoolyearId),callback:function ($$v) {_vm.selectedSchoolyearId=$$v},expression:"selectedSchoolyearId"}})],1):_vm._e(),(_vm.lesson.group && _vm.lesson.group.is_tutor)?_c('div',{staticClass:"field"},[_c('vs-input',{attrs:{"label":"Lesson duration, minutes","size":"large"},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1):_vm._e(),(
          _vm.lesson.lesson_type == 'tutor' ||
            (_vm.lesson.group && _vm.lesson.group.group_type.with_fixed_rate)
        )?_c('div',{staticClass:"field"},[_c('vs-input',{attrs:{"label":"Lesson price","size":"large"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1):_vm._e(),(_vm.lesson.cancelled)?_c('vs-checkbox',{model:{value:(_vm.cancelled),callback:function ($$v) {_vm.cancelled=$$v},expression:"cancelled"}},[_vm._v("Cancelled")]):_vm._e(),_c('div',{staticClass:"field other-teachers"},[_c('label',{attrs:{"for":""}},[_vm._v("Sub teachers")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","multiple":"","clearable":"","placeholder":"Select teacher","selectable":function (option) { return option.id != _vm.mainTeacherId &&
              !_vm.otherTeachers.includes(option.id) &&
              !_vm.assistants.includes(option.id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined,"size":"small"}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
                  var nickname = ref.nickname;
                  var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined,"size":"small"}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.otherTeachers),callback:function ($$v) {_vm.otherTeachers=$$v},expression:"otherTeachers"}})],1),_c('div',{staticClass:"field assistants"},[_c('label',{attrs:{"for":""}},[_vm._v("Assistants")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","multiple":"","clearable":"","placeholder":"Select teacher","selectable":function (option) { return option.id != _vm.mainTeacherId &&
              !_vm.otherTeachers.includes(option.id) &&
              !_vm.assistants.includes(option.id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
                  var nickname = ref.nickname;
                  var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
                  ? (_vm.proxy + "/static/files/" + (options.avatar))
                  : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.assistants),callback:function ($$v) {_vm.assistants=$$v},expression:"assistants"}})],1)],1),_c('div',{staticClass:"inline-part right"},[_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Lesson date and time")]),_c('v-date-picker',{attrs:{"mode":"dateTime","is24hr":"","minute-increment":15},model:{value:(_vm.lesson_time),callback:function ($$v) {_vm.lesson_time=$$v},expression:"lesson_time"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":"online"}},[_vm._v("Lesson is online")]),_c('vs-switch',{attrs:{"id":"online"},model:{value:(_vm.is_online),callback:function ($$v) {_vm.is_online=$$v},expression:"is_online"}},[_c('span',{attrs:{"slot":"on"},slot:"on"},[_vm._v("Online")]),_c('span',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Offline")])])],1)])]),_c('vs-button',{attrs:{"icon":"save"},on:{"click":_vm.save}},[_vm._v("Save")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }