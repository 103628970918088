<template>
  <div class="lesson-form">
    <div
      class="lesson-form__status"
      :class="{ cancelled: lesson.cancelled }"
      v-if="lesson.cancelled"
    >
      Lesson Cancelled
    </div>
    <div
      class="lesson-form__status"
      :class="{ started: lesson.date_start && !lesson.date_end }"
      v-if="lesson.date_start && !lesson.date_end"
    >
      Lesson in work
    </div>
    <div
      class="lesson-form__status"
      :class="{ done: lesson.date_end }"
      v-if="lesson.date_end"
    >
      Lesson done
    </div>

    <div class="groups__form-content form-content-two-parts">
      <div class="inline-part left">
        <div class="field">
          <vs-input v-model="name" label="Lesson name" size="large"></vs-input>
        </div>
        <div
          class="field"
          v-if="lesson.lesson_type == 'testing' || groupWithFixedRate"
        >
          <label for="">Students</label>
          <v-select
            label="name"
            :options="studentsList"
            v-model="students"
            :reduce="item => item.id"
            clearable
            multiple
            placeholder="Select students"
            :class="{ error: !$v.students.required }"
            :selectable="studentSelectableHandler"
          >
            <template #option="{ name }">
              <vs-avatar icon="person"></vs-avatar>
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              <vs-avatar icon="person"></vs-avatar>
              {{ name }}
            </template>
          </v-select>
          <vs-button size="small" type="flat" @click="selectAllStudentsHandler"
            >Select all</vs-button
          >
          <vs-button
            size="small"
            type="flat"
            color="danger"
            @click="students = []"
            >Remove all</vs-button
          >
        </div>
        <div class="field">
          <label for="">Teacher</label>
          <v-select
            label="nickname"
            :options="teachers"
            v-model="teacher_id"
            :reduce="item => item.id"
            autocomplete="on"
            clearable
            placeholder="Select teacher"
          >
            <template #option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
                size="small"
              ></vs-avatar
              >{{ nickname }}
            </template>
            <template #selected-option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
                size="small"
              ></vs-avatar>
              {{ nickname }}
            </template>
          </v-select>
        </div>

        <div class="field">
          <label for="">Room</label>
          <v-select
            label="name"
            :options="rooms"
            v-model="room_id"
            :reduce="item => item.id"
            autocomplete="on"
            clearable
            placeholder="Select room"
            :class="{ error: !$v.room_id.required }"
          >
            <template #option="{ name, icon }">
              <vs-icon :icon="icon"></vs-icon>
              {{ name }}
            </template>
            <template #selected-option="{ name, icon }">
              <vs-icon :icon="icon"></vs-icon>
              {{ name }}
            </template>
          </v-select>
          <div v-if="!$v.room_id.required" class="error">Field is required</div>
        </div>
        <div class="field" v-if="lesson.lesson_type == 'tutor'">
          <label for="">School Year</label>
          <v-select
            label="name"
            :options="years"
            v-model="selectedSchoolyearId"
            :reduce="item => item.id"
            autocomplete="on"
            clearable
            placeholder="Select schoolyear"
          >
            <template #selected-option="{ name }">
              {{ name }}
            </template>
          </v-select>
        </div>
        <div class="field" v-if="lesson.group && lesson.group.is_tutor">
          <vs-input
            v-model="duration"
            label="Lesson duration, minutes"
            size="large"
          ></vs-input>
        </div>
        <div
          class="field"
          v-if="
            lesson.lesson_type == 'tutor' ||
              (lesson.group && lesson.group.group_type.with_fixed_rate)
          "
        >
          <vs-input
            v-model="price"
            label="Lesson price"
            size="large"
          ></vs-input>
        </div>
        <vs-checkbox v-model="cancelled" v-if="lesson.cancelled"
          >Cancelled</vs-checkbox
        >
        <div class="field other-teachers">
          <label for="">Sub teachers</label>
          <v-select
            label="nickname"
            :options="teachers"
            v-model="otherTeachers"
            :reduce="item => item.id"
            autocomplete="on"
            multiple
            clearable
            placeholder="Select teacher"
            :selectable="
              option =>
                option.id != mainTeacherId &&
                !otherTeachers.includes(option.id) &&
                !assistants.includes(option.id)
            "
          >
            <template #option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
                size="small"
              ></vs-avatar
              >{{ nickname }}
            </template>
            <template #selected-option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
                size="small"
              ></vs-avatar>
              {{ nickname }}
            </template>
          </v-select>
        </div>
        <div class="field assistants">
          <label for="">Assistants</label>
          <v-select
            label="nickname"
            :options="teachers"
            v-model="assistants"
            :reduce="item => item.id"
            autocomplete="on"
            multiple
            clearable
            placeholder="Select teacher"
            :selectable="
              option =>
                option.id != mainTeacherId &&
                !otherTeachers.includes(option.id) &&
                !assistants.includes(option.id)
            "
          >
            <template #option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ nickname }}
            </template>
            <template #selected-option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
              ></vs-avatar>
              {{ nickname }}
            </template>
          </v-select>
        </div>
      </div>
      <div class="inline-part right">
        <div class="field">
          <label for="">Lesson date and time</label>
          <v-date-picker
            mode="dateTime"
            v-model="lesson_time"
            is24hr
            :minute-increment="15"
          />
        </div>
        <div class="field">
          <label for="online">Lesson is online</label>
          <vs-switch id="online" v-model="is_online">
            <span slot="on">Online</span>
            <span slot="off">Offline</span>
          </vs-switch>
        </div>
      </div>
    </div>
    <vs-button icon="save" @click="save">Save</vs-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import StudentStatuses from '@/components/common/statuses/studentStatuses'
// import LessonOtherTeacherForm from "./lessonOtherTeacherForm.vue";
export default {
  props: ['lesson'],
  data() {
    let students = this.lesson.students.map(s => s.id)

    let data = {
      name: this.lesson.name,
      lesson_time: new Date(this.lesson.lesson_time * 1000),
      teacher_id: this.lesson.teacher.id,
      room_id: this.lesson.room.id,
      cancelled: this.lesson.cancelled,
      students: students || [],
      duration: this.lesson.duration,
      price: this.lesson.price,
      proxy: process.env.VUE_APP_PROXY,
      selectedSchoolyearId: this.lesson.schoolyear_id,
      groupWithFixedRate: null,
      otherTeachers: [],
      assistants: [],
      is_online: this.lesson.is_online,
    }
    if (this.lesson.group !== null) {
      data = {
        ...data,
        groupStudents: this.lesson.group.students,
        groupWithFixedRate: this.lesson.group.group_type.with_fixed_rate,
      }
    }
    return data
  },
  validations: {
    teacher_id: {
      required,
    },
    room_id: {
      required,
    },
    students: {
      required,
    },
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      rooms: state => state.rooms.rooms,
      years: state => state.years.years,
      currentYear: state => state.years.currentSchoolYear,
      studentsAllList: state => state.students.students,
    }),
    studentsList: function() {
      if (this.lesson.group !== null) {
        return this.lesson.group.students
        // return this.lesson.group.students.filter(
        //   (s) => this.students.indexOf(s.id) < 0,
        // );
      }
      if (this.lesson.lesson_type == 'testing') {
        let allStudentsList = [...this.studentsAllList]

        let students = allStudentsList.concat(
          this.lesson.students.filter(
            ({ id }) => !allStudentsList.find(f => f.id == id)
          )
        )
        return students
      }
      return this.lesson.students
    },
    mainTeacherId: function() {
      return this.lesson.teacher.id
    },
  },
  components: {
    vSelect,
    // LessonOtherTeacherForm,
  },
  methods: {
    save: function() {
      let {
        name,
        lesson_time,
        teacher_id,
        room_id,
        cancelled,
        students,
        price,
        duration,
        selectedSchoolyearId,
        otherTeachers,
        assistants,
        is_online,
      } = this
      let { id, lesson_type } = this.lesson
      lesson_time = lesson_time.getTime() / 1000

      const data = {
        id,
        lesson_type,
        name,
        lesson_time,
        teacher_id,
        room_id,
        cancelled,
        duration,
        assistants,
        is_online,
        other_teachers: otherTeachers,
      }
      if (
        this.groupWithFixedRate ||
        this.lesson.lesson_type == 'tutor' ||
        this.lesson.lesson_type == 'testing'
      ) {
        data.students = students
        data.price = parseFloat(price)
      }
      if (selectedSchoolyearId) {
        data.schoolyear_id = selectedSchoolyearId
      } else if (this.currentYear.id) {
        data.schoolyear_id = this.currentYear.id
      }

      this.$store.dispatch('lessons/setLessonsAction', { data }).then(() => {
        this.$emit('closePopup', this.closePopup)
        if (lesson_type == 'testing') {
          this.$store.dispatch('results/getIncomeTestsResultsByLessonAction', {
            lesson_id: id,
          })
        }
      })
    },
    selectAllStudentsHandler: function() {
      this.students = this.studentsList.map(s => s.id)
    },
    studentSelectableHandler: function(option) {
      if (this.students.indexOf(option.student_id) >= 0) return false
      return true
    },
    changeTeachersHandler: function(teachers) {
      Object.assign(this, teachers)
    },
  },
  created: async function() {
    await this.$store.dispatch('teachers/getTeachersAction')
    await this.$store.dispatch('rooms/getRoomsAction')
    await this.$store.dispatch('years/getYearsAction')
    if (this.lesson.id) {
      if (this.lesson.other_teachers)
        this.otherTeachers = Object.keys(this.lesson.other_teachers).map(i =>
          parseInt(i, 10)
        )
      if (this.lesson.assistants)
        this.assistants = Object.keys(this.lesson.assistants).map(i =>
          parseInt(i, 10)
        )
    }
    if (this.lesson.lesson_type == 'testing') {
      let filter = {
        schoolyear_id: this.currentYear.id,
        status: StudentStatuses.testing,
      }
      await this.$store.dispatch('students/getStudentsAction', filter)
    }
  },
}
</script>

<style lang="scss" scoped>
.lesson-form {
  .lesson-form__status {
    width: auto;
    padding: 15px;
    border-radius: 7px;
    font-weight: bold;
    &.cancelled {
      background-color: var(--var-cancelled);
    }
    &.done {
      background-color: var(--var-started);
    }
    &.started {
      background-color: #eee;
    }
  }
}
</style>
