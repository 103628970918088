<template>
  <div class="tasks">
    <div class="task-wrapper collapsible">
      <div class="task-items collapsible" :class="{ opened }">
        <div class="task-items__header collapsible-header">
          <div class="collapsible__button" @click="opened = !opened">
            <vs-icon
              icon="chevron_right"
              class="small-button collapsible__button__arrow"
              color="dark"
            ></vs-icon>
          </div>
          <h3>Tasks ({{ tasks.length }})</h3>
          <div class="status-bar__manage-buttons full-size-button">
            <vs-button
              icon="add_task"
              class="status-bar__edit-button"
              type="flat"
              color="primary"
              @click="editPopupActive = true"
              size="small"
              v-if="$can('manage', 'add_task')"
              >Add task</vs-button
            >
          </div>
        </div>
        <div class="collapsible_content" v-if="opened">
          <div class="task-list">
            <div
              v-for="task in tasks"
              :key="task.id"
              @click="taskViewHandler(task)"
            >
              <task-list-item :task="task" :place_type="place_type" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="viewPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-view"
        classContent="task__popup-content-edit-view"
        :title="`Task #${selectedTask.id}`"
        :active.sync="viewPopupActive"
        @close="closeTaskPopupHandler"
      >
        <task-view
          :id="selectedTask.id"
          @editTask="editTaskHandler"
          @taskSaved="taskUpdateHandler"
        />
      </vs-popup>
    </div>
    <div v-if="editPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-edit"
        classContent="task__popup-content-edit"
        :title="selectedTask ? `Edit task #${selectedTask.id}` : 'Create task'"
        :active.sync="editPopupActive"
      >
        <task-form
          @taskSaved="taskUpdateHandler"
          :place_type="place_type"
          :place_id="place_id"
          :task="selectedTask"
          :lesson="lesson"
          :group="group"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TaskForm from './taskForm.vue'
import TaskListItem from './taskListItem.vue'
import TaskView from './taskView.vue'
import getTasks from '@/api/tasks/getTasks'
import eventBus from '@/eventBus.js'

export default {
  props: {
    place_id: {
      type: Number,
    },
    place_type: {
      type: String,
      validator: function(value) {
        // Значение должно соответствовать одной из этих строк
        return ['teacher', 'group', 'lesson', 'student'].indexOf(value) !== -1
      },
    },
    lesson: {
      type: Object,
    },
    group: {
      type: Object,
    },
  },
  data() {
    return {
      opened: true,
      editPopupActive: false,
      viewPopupActive: false,
      selectedTask: null,
      tasks: [],
    }
  },
  components: {
    TaskForm,
    TaskListItem,
    TaskView,
  },
  computed: {
    ...mapState({
      // tasks: (state) => state.tasks.tasks,
      teachers: state => state.teachers.teachers,
    }),
    filterByPlace: function() {
      let filter = {}
      if (!this.place_id) return filter
      switch (this.place_type) {
        case 'group':
          filter = { group_id: this.place_id, status: [0, 1] }
          break
        case 'lesson':
          filter = { lesson_id: this.place_id }
          break
        case 'student':
          filter = { student_id: this.place_id, status: [0, 1] }
          break
        default:
          filter = {
            teacher_ids: [this.place_id],
            status: [0, 1],
          }
          break
      }
      return filter
    },
  },
  methods: {
    getTasks: async function() {
      const filters = {
        ...this.filterByPlace,
        sort: '-created',
      }
      const response = await getTasks.call(this, filters)
      if (response.data) {
        this.tasks = response.data
      }
      // await this.$store.dispatch("tasks/getTasks", filters);
    },
    taskUpdateHandler: async function() {
      await this.getTasks()
      if (this.selectedTask) {
        this.$store.dispatch('tasks/getSingleTask', this.selectedTask.id)
        // this.selectedTask = this.tasks.find(t => t.id == this.selectedTask.id)
      } else {
        this.viewPopupActive = false
        // Обновляем комменты только если занятие закрыто
        eventBus.$emit('updateCommentsList')
      }
      this.editPopupActive = false
    },
    taskViewHandler: function(task) {
      this.selectedTask = task
      this.viewPopupActive = true
    },
    editTaskHandler: function() {
      //   this.viewPopupActive = false;
      this.editPopupActive = true
    },
    closeTaskPopupHandler: function() {
      this.selectedTask = null
    },
  },
  created: async function() {
    await this.getTasks()
    if (!this.teachers.length) {
      await this.$store.dispatch('teachers/getTeachersAction')
    }
    eventBus.$on('updateTasksList', async () => {
      await this.getTasks()
    })
  },

  beforeDestroy() {
    eventBus.$off('updateTasksList')
  },
}
</script>

<style lang="scss">
.task-wrapper {
  padding: 1.5rem;
  background-color: #f1f1f1;
  margin: 0 1rem 1rem 0;
  box-shadow: 0px 0px 3px 2px #ddd;
  border-radius: 1rem;
}
.tasks {
  margin-top: 1rem;
  h3 {
    margin-right: 1rem;
  }
}
.task__popup-content-edit {
  height: 100%;
}
</style>
